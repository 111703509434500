<template>
  <div class="forget-content" :style="forgetBgImg">
    <div class="forget-box">
      <div class="forget-header">
        <span class="forget-header-title">{{
          $t("userManager.resetPwd")
        }}</span>
        <el-link
          icon="el-icon-user-solid"
          class="forget-header-home"
          type="info"
          :underline="false"
          @click="login"
          >{{ $t("forget.retryLogin") }}</el-link
        >
      </div>
      <div class="forget-steps">
        <el-steps
          :active="active"
          align-center
          finish-status="success"
          process-status="finish"
        >
          <el-step
            :title="$t('forget.accountInfo')"
            icon="el-icon-user"
          ></el-step>
          <el-step
            :title="$t('forget.captcha')"
            icon="el-icon-mobile-phone"
          ></el-step>
          <el-step
            :title="$t('userManager.resetPwd')"
            icon="el-icon-refresh-right"
          ></el-step>
          <el-step
            :title="$t('commons.competed')"
            icon="el-icon-circle-check"
          ></el-step>
        </el-steps>
      </div>
      <div class="mod-forgot">
        <!--第1步-->
        <div
          v-if="display.accountStep"
          @submit.native.prevent
          class="forget-container"
        >
          <el-form
            key="accountForm"
            :model="accountForm"
            ref="accountForm"
            :rules="accountRule"
            class="forget-form"
            label-position="top"
          >
            <el-form-item prop="userAccount" :label="$t('forget.entryAccount')">
              <el-input
                v-model="accountForm.userAccount"
                maxlength="40"
                :placeholder="$t('forget.entryAccount')"
                class="pass-input-forgot"
              ></el-input>
            </el-form-item>
            <el-form-item prop="captcha">
              <el-input
                v-model="accountForm.captcha"
                maxlength="10"
                class="vcode-input-width"
                :placeholder="$t('userManager.captchaRequired')"
              ></el-input>
              <img :src="vCode" class="vcode-img-big" @click="changeVCode" />
            </el-form-item>
            <el-form-item>
              <el-button style="width: 73%" type="primary" @click="next">{{
                nextext
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!--第2步-->
        <div v-if="display.captchaStep" class="forget-container">
          <el-form
            key="captchaForm"
            @submit.native.prevent
            :model="captchaForm"
            ref="captchaForm"
            :rules="captchaRule"
            class="forget-form"
            label-position="top"
          >
            <el-form-item prop="authCaptcha" :label="$t('forget.entryCaptcha')">
              <el-input
                v-model="captchaForm.authCaptcha"
                maxlength="10"
                :placeholder="$t('forget.entryCaptcha')"
                class="pass-input-forgot"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="width: 73%" type="primary" @click="next">{{
                nextext
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!--第3步-->
        <div v-if="display.confirmStep" class="forget-container">
          <el-form
            key="confirmForm"
            @submit.native.prevent
            :model="confirmForm"
            ref="confirmForm"
            :rules="confirmRule"
            class="forget-form"
            label-position="top"
          >
            <el-form-item prop="password" :label="$t('forget.entryPassword')">
              <el-input
                v-model="confirmForm.password"
                maxlength="50"
                :show-word-limit="true"
                :placeholder="$t('userManager.newPasswordRequired')"
                class="pass-input-forgot"
              ></el-input>
            </el-form-item>
            <el-form-item prop="confirmPassword">
              <el-input
                v-model="confirmForm.confirmPassword"
                maxlength="50"
                :show-word-limit="true"
                :placeholder="$t('userManager.passwordReconfirm')"
                class="pass-input-forgot"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button style="width: 73%" type="primary" @click="next">{{
                nextext
              }}</el-button>
            </el-form-item>
          </el-form>
        </div>

        <!--第4步-->
        <div v-if="display.successStep" class="forget-container">
          <div class="forget-form" style="text-align: center; margin-top: 60px">
            <span style="font-size: 22px">{{ $t("forget.resetSuccess") }}</span>
            <el-button type="text" style="font-size: 22px" @click="login">{{
              $t("forget.retryLogin")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="forget-box-shadow"></div>
  </div>
</template>

<script>
import { envInfo } from "@/constants/envInfo";
import router from "@/router";

let manager = require("@/api/ruge/forget.js");
import { getCaptchaPicture } from "@/api/ruge/sso/personal/personal.js";
// import {isValidUserAccount} from '@/utils/validate'
import { encrypt2RSA } from "@/utils/encrypt";
import { Base64 } from "js-base64";
import { writeCookie } from "@/api/ruge/login";

export default {
  data() {
    return {
      active: 0,
      nextext: this.$t("commons.nextStep"),
      vCode: "",
      asynValid: {
        nExistAccount: false,
        unMatchCaptch: false,
        captchExpire: false,
      },
      display: {
        accountStep: true,
        captchaStep: false,
        confirmStep: false,
        successStep: false,
      },
      accountForm: {
        userAccount: "",
        captcha: "",
      },
      accountRule: {
        userAccount: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (value && value.length > 60) {
                callback(
                  new Error(
                    this.$t("userManager.validMessage.userAccountOutLen")
                  )
                );
              }
              // if(!isValidUserAccount(value)) {
              //     callback(new Error(this.$t('userManager.validMessage.userAccountRegx')));
              // }
              callback();
            },
            trigger: ["blur", "change"],
          },
          {
            validator: (rule, value, callback) => {
              if (this.asynValid.nExistAccount) {
                callback(
                  new Error(this.$t("forget.validMessage.nExistAccount"))
                );
              }
              callback();
            },
          },
        ],
        captcha: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (this.asynValid.unMatchCaptch) {
                callback(
                  new Error(this.$t("forget.validMessage.unMatchCaptch"))
                );
              }
              callback();
            },
          },
          {
            validator: (rule, value, callback) => {
              if (this.asynValid.captchExpire) {
                callback(
                  new Error(this.$t("forget.validMessage.captchExpire"))
                );
              }
              callback();
            },
          },
        ],
      },
      captchaForm: {
        authCaptcha: null,
      },
      captchaRule: {
        authCaptcha: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (this.asynValid.unMatchCaptch) {
                callback(
                  new Error(this.$t("forget.validMessage.unMatchCaptch"))
                );
              }
              callback();
            },
          },
        ],
      },
      confirmForm: {
        token: "",
        password: null,
        confirmPassword: null,
      },
      confirmRule: {
        password: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (
                this.confirmForm.confirmPassword &&
                value != this.confirmForm.confirmPassword
              ) {
                callback(new Error(this.$t("forget.validMessage.unMatchPwd")));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
        confirmPassword: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
          {
            validator: (rule, value, callback) => {
              if (
                this.confirmForm.password &&
                value != this.confirmForm.password
              ) {
                callback(new Error(this.$t("forget.validMessage.unMatchPwd")));
              }
              callback();
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      forgetBgImg: {
        // "background": "url(" + require('@/assets/images/loginBgImg.jpg') + ") center center",
        overflow: "scroll",
      },
    };
  },
  created() {
    this.changeVCode();
  },
  methods: {
    changeVCode() {
      getCaptchaPicture().then((res) => {
        if (res.loginLock === "1") {
          this.$message.warning(`请${res.leftLockExpireTime}秒后再试!`);
          return;
        }
        this.vCode = res.image;
        const bastAuthToken = Base64.encode(res.authToken);
        writeCookie(bastAuthToken).then((result) => {
          this.$store.commit("SET_AUTH_TOKEN", res.authToken);
        });
      });
    },
    next() {
      if (this.active == 0) {
        this.asynValid.unMatchCaptch = false;
        this.asynValid.nExistAccount = false;
        this.asynValid.captchExpire = false;
        this.$refs.accountForm.validate((v) => {
          if (v) {
            //验证图形码并发送手机验证码
            manager
              .sendCaptcha(
                this.accountForm.userAccount,
                this.accountForm.captcha
              )
              .then((r) => {
                switch (r) {
                  case -1:
                    this.asynValid.unMatchCaptch = true;
                    this.$refs.accountForm.validateField(["captcha"]);
                    break;
                  case -2:
                    this.asynValid.nExistAccount = true;
                    this.$refs.accountForm.validateField(["userAccount"]);
                    break;
                  case -3:
                    this.asynValid.captchExpire = true;
                    this.$refs.accountForm.validateField(["captcha"]);
                    break;
                  default:
                    break;
                }
                if (r == 1) {
                  //跳转第二步
                  this.display.accountStep = false;
                  this.display.captchaStep = true;
                  this.display.confirmStep = false;
                  this.display.successStep = false;
                  this.active++;
                }
              });
          }
        });
      } else if (this.active == 1) {
        this.asynValid.unMatchCaptch = false;
        this.$refs.captchaForm.validate((v) => {
          if (v) {
            //验证验证码
            manager
              .captchaAuth(
                this.accountForm.userAccount,
                this.captchaForm.authCaptcha
              )
              .then((r) => {
                if (r.token) {
                  this.confirmForm.token = r.token;

                  //跳转第三步
                  this.display.accountStep = false;
                  this.display.captchaStep = false;
                  this.display.confirmStep = true;
                  this.display.successStep = false;
                  this.nextext = this.$t("commons.confirm");
                  this.active++;
                } else {
                  this.asynValid.unMatchCaptch = true;
                  this.$refs.captchaForm.validateField("authCaptcha");
                }
              });
          }
        });
      } else if (this.active == 2) {
        this.$refs.confirmForm.validate((v) => {
          if (v) {
            manager
              .confirmReset(
                this.accountForm.userAccount,
                this.confirmForm.token,
                encrypt2RSA(this.confirmForm.password)
              )
              .then((r) => {
                if (r > 0) {
                  //重置密码
                  //成功跳转第四步提示
                  this.display.accountStep = false;
                  this.display.captchaStep = false;
                  this.display.confirmStep = false;
                  this.display.successStep = true;
                  this.active++;
                } else {
                  //
                  //TODO
                }
              });
          }
        });
      }
    },
    login() {
      router.replace({ path: "/login" });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.forget-content {
  position: fixed;
  height: 100%;
  width: 100%;
  background: linear-gradient(360deg, #397bf5, #45bffb);

  .forget-box,
  .forget-box-shadow {
    position: absolute;
    left: 0;
    right: 0;
    width: 1100px;
    height: 550px;
    max-width: 100%;
    margin: 120px auto;
    z-index: 99;
    border-radius: 8px;
    background: #ffffff;
    top: calc(50vh - 400px);
  }

  .forget-box-shadow {
    z-index: 90;
    background: #333;
    opacity: 0.3;
    margin-top: 130px;
    border-radius: 10px;
    left: 50%;
    margin-left: -540px;
  }

  .forget-steps {
    padding: 30px 50px;
  }

  .forget-header {
    line-height: 40px;
    margin: 10px 50px;

    .forget-header-title {
      font-size: 18px;
      font-weight: 500;
    }

    .forget-header-home {
      float: right;
    }
  }
}

.mod-forgot {
  height: 300px;
  margin-left: 70px;
  width: auto;

  .pass-input-forgot {
    height: 16px;
    width: 328px;
    margin-right: 10px;
  }
}

.forget-container {
  height: 100%;
  width: 100%;

  .forget-form {
    left: 0;
    right: 0;
    width: 520px;
    max-width: 100%;
    padding: 35px 35px 15px 35px;
    margin: 0 auto;
  }

  .vcode-input-width {
    float: left;
    width: 218px;
    margin-right: 0px;
  }

  .vcode-img-big {
    float: left;
    width: 100px;
    height: 36px;
    border: 1px solid #ddd;
    margin-left: 10px;
    cursor: pointer;
  }

  .vcode-img-change {
    padding-left: 10px;
    padding-top: 13px;
    display: block;
    padding-top: 9px;
    float: left;
  }
}
</style>

