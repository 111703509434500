import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 发送验证码（第一步）
 * @param username
 * @param captcha 防护码
 */
export function sendCaptcha(username, captcha) {
    return request({
      url: envInfo.bgApp.ssoPath + '/sso/secret/sendCaptchaPhone',
      method: 'post',
      data: {
        userAccount : username,
        captcha : captcha
      }
    })
  }


/**
 * 提交验证码(第二步)
 * @param username
 * @param authCaptcha 手机码
 */
export function captchaAuth(username, authCaptcha) {
  return request({
    url: envInfo.bgApp.ssoPath + '/sso/secret/captchaAuth',
    method: 'post',
    data: {
      userAccount : username,
      authCaptcha : authCaptcha
    }
  })
}

/**
 * 确认重置(第三步)
 * @param {string} username 
 * @param {string} token 
 * @param {string} password RSA加密后的密码
 */
export function confirmReset(username, token, password) {
    return request({
      url: envInfo.bgApp.ssoPath + '/sso/secret/resetPwd',
      method: 'post',
      data: {
        userAccount : username,
        token : token,
        password: password
      }
    })
  }


